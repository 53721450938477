import BaseInstance from "../base.instance";
import {
  SIMPEG_PEGAWAI_LIST_PENGAJUAN,
  SIMPEG_PEGAWAI_DETAIL_PENGAJUAN,
  SIMPEG_PEGAWAI_VALIDATION,
  SIMPEG_PEGAWAI_APPROVAL
} from "../constants";

const PegawaiSubmissionService = {
  getList(data) {
    return BaseInstance.post(SIMPEG_PEGAWAI_LIST_PENGAJUAN, data);
  },
  getDetail(data) {
    return BaseInstance.post(SIMPEG_PEGAWAI_DETAIL_PENGAJUAN, data);
  },
  validation(data) {
    return BaseInstance.post(SIMPEG_PEGAWAI_VALIDATION, data);
  },
  approve(data) {
    return BaseInstance.post(SIMPEG_PEGAWAI_APPROVAL, data);
  }
};

export default PegawaiSubmissionService;
